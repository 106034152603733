// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
const routes = [{
    path: '/',
    component: () => import('@/views/xjdxx/index.vue'),
    meta: {
      title: '江苏新节点'
    } // 页面标题
  },
  {
    path: '/index',
    component: () => import('@/views/xjdxx/index.vue'),
    meta: {
      title: '江苏新节点'
    } // 页面标题
  },
  {
    path: '/case',
    component: () => import('@/views/xjdxx/case.vue'),
    meta: {
      title: '江苏新节点'
    } // 页面标题
  },
  {
    path: '/ask',
    component: () => import('@/views/xjdxx/ask.vue'),
    meta: {
      title: '江苏新节点'
    } // 页面标题
  },
  {
    path: '/about',
    component: () => import('@/views/xjdxx/about.vue'),
    meta: {
      title: '江苏新节点'
    } // 页面标题
  },
  // 其他页面的路由配置
];

const router = createRouter({
  history: createWebHistory(),
  routes
});


// 设置页面标题
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '江苏新节点信息技术有限公司';
  next();
});

export default router;