import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/styles/index.scss' // global css
import '@/assets/styles/animate-3.7.0.css' // global css

const app = createApp(App);
app.use(ElementPlus)
app.use(router);
app.mount('#app');